.card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.form-control {
    /*width: 200px; */
}

.rounded-circle {
    border-radius: 50%;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.content {
    margin-left: 20px; /* Adjust the margin value as needed */
}



/* Add additional styling as needed for the profile information section */

  /* Add custom CSS to set dot colors */
  .fc-event-dot {
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 4px;
  }
  .fc-event, .fc-event-dot {
    background-color: none !important;
}
  .status-present .fc-event-dot {
    background-color: green;
  }
  .status-halfday .fc-event-dot {
    background-color: yellow;
  }
  .status-sunday .fc-event-dot {
    background-color: orange;
  }
  .status-absent .fc-event-dot {
    background-color: red;
  }
  .status-absent{
      background-color: red;
  }
  .status-present{
      background-color: green;
  }
  .status-halfday{
      background-color: yellow;
  }
  .status-sunday{
      background-color: orange;
  }
  .fc-event {
    background-color: transparent;
     border:none!important;
     /*cursor: pointer!important; */
}
.fc-day{
  cursor: pointer!important;
}



.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
    border: 1px solid #d2d6de;
    border-radius: 0;
    padding: 6px 12px;
    height: 34px
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 10px
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    margin-top: -3px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
    border: 1px solid #d2d6de;
    border-radius: 0 !important;
    padding: 6px 12px;
    height: 40px !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 6px !important;
    right: 1px;
    width: 20px
}
.select2-container {
    display:block;
    width: 425px !important;
    outline: none;
}
.status-text{
	  background-color: black;
  }
  body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625;
  color: #666;
}
.fc-event, .fc-event:hover {
  color: #000!important;
}

